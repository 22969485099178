import { Flex, Skeleton, Table, Text, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { LocationResolverService } from "../../services";
import { Country } from "../../types";

export function CountryReport(props: {}) {
  const locationResolverService: LocationResolverService =
    new LocationResolverService();

  const [countries, setCountries] = useState<Country[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getAllCountries();
  }, []);

  async function getAllCountries() {
    setLoading(true);
    const countries = await locationResolverService.getAllCountries();
    setCountries(countries);
    setFilteredCountries(countries);
    setLoading(false);
  }

  async function filterCountries(searchKeyword: string) {
    setLoading(true);
    let filteredCountries: Country[] = [];
    if (searchKeyword.length != 0) {
      filteredCountries = countries.filter(
        (country) =>
          country.countryName
            .toLowerCase()
            .includes(searchKeyword.toLowerCase()) ||
          country.fullName
            .toLowerCase()
            .includes(searchKeyword.toLowerCase()) ||
          country.code.toLowerCase().includes(searchKeyword.toLowerCase()),
      );
    } else {
      filteredCountries = countries;
    }
    setFilteredCountries(filteredCountries);
    setLoading(false);
  }

  const rows = filteredCountries.map((country) => (
    <Table.Tr key={country.countryId}>
      <Table.Td>{country.countryId}</Table.Td>
      <Table.Td>{country.countryName}</Table.Td>
      <Table.Td>{country.fullName}</Table.Td>
      <Table.Td>{country.code}</Table.Td>
      <Table.Td>{country.iso3}</Table.Td>
      <Table.Td>{country.capital}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Flex px={12} direction={"column"}>
      <Text fw={500} mb={8}>
        Country Report
      </Text>

      <form>
        <Flex>
          <TextInput
            w={300}
            label={"Search Country"}
            size="sm"
            placeholder="Country name or Full Name or Code"
            onChange={(event) => {
              filterCountries(event.target.value);
            }}
            mr={8}
          />
        </Flex>
      </form>

      <Table
        my={16}
        striped
        stickyHeader
        stickyHeaderOffset={10}
        highlightOnHover
      >
        <Table.Thead bg={"black"} c={"white"}>
          <Table.Tr>
            <Table.Th>Country Id</Table.Th>
            <Table.Th>Country Name</Table.Th>
            <Table.Th>Full Name</Table.Th>
            <Table.Th>Code (ISO2)</Table.Th>
            <Table.Th>Code (ISO3)</Table.Th>
            <Table.Th>Capital</Table.Th>
          </Table.Tr>
        </Table.Thead>
        {loading && (
          <Table.Tbody>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
              <Table.Tr key={index}>
                <Table.Td colSpan={8}>
                  <Skeleton height={20} my={2} width={"100%"} />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        )}
        {!loading && rows.length > 0 && <Table.Tbody>{rows}</Table.Tbody>}
        {!loading && rows.length === 0 && (
          <Table.Tbody>
            <Table.Tr>
              <Table.Td colSpan={8} h={200}>
                <Text ta={"center"} fw={500}>
                  No country data found.
                </Text>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        )}
      </Table>
    </Flex>
  );
}
