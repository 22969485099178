import { Box, Drawer, Flex, NavLink } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconBriefcase,
  IconGridDots,
  IconTimezone,
  IconUser,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AdminJobsReport } from "../../components";
import { CountryReport } from "../../components/admin/countryReport.component";
import { RegionReport } from "../../components/admin/regionReport.component";
import { JobService, LocationResolverService } from "../../services";
import { RootState } from "../../store";
import { ScreenSize } from "../../utils";

export function AdminDashboardPage(props: {}) {
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);
  const user = useSelector((state: RootState) => state.auth.user);
  const [active, setActive] = useState(0);

  const [drawerOpened, setDrawerOpened] = useState(false);
  const locationResolverService: LocationResolverService =
    new LocationResolverService();
  const jobService: JobService = new JobService();

  const data = [
    {
      icon: IconUser,
      label: "Users",
      description: "user report section",
      href: "#users",
    },
    {
      icon: IconBriefcase,
      label: "Jobs report",
      href: "#jobs",
      description: "jobs report section",
    },
    {
      icon: IconTimezone,
      label: "Country report",
      href: "#country",
      description: "country report section",
    },
    {
      icon: IconTimezone,
      label: "Region report",
      href: "#region",
      description: "region report section",
    },
    {
      icon: IconTimezone,
      label: "City report",
      href: "#city",
      description: "city report section",
    },
  ];

  useEffect(() => {
    locationResolverService.getAllCountries();
    jobService.findAllIndustries();
  }, []);

  const items = data.map((item, index) => (
    <NavLink
      href={item.href}
      key={item.label}
      active={index === active}
      label={item.label}
      description={item.description}
      leftSection={<item.icon size={16} stroke={1.5} />}
      onClick={() => setActive(index)}
    />
  ));

  return (
    <Flex px={32} py={12}>
      <Drawer
        opened={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        overlayProps={{ backgroundOpacity: 0.2, blur: 4 }}
        transitionProps={{
          duration: 300,
        }}
        styles={{
          content: {
            height: "250",
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
          },
          overlay: {
            height: "250",
          },
        }}
      >
        <Box>{items}</Box>
      </Drawer>
      {isDesktop && <Box w={320}>{items}</Box>}
      {(isMobile || isTab) && !isDesktop && (
        <IconGridDots
          style={{ cursor: "pointer" }}
          onClick={() => setDrawerOpened(true)}
        />
      )}
      {active === 0 && (
        <Box flex={1} ml={16}>
          Users | work in progress
        </Box>
      )}
      {active === 1 && (
        <Box flex={1} ml={16}>
          <AdminJobsReport />
        </Box>
      )}
      {active === 2 && (
        <Box flex={1} ml={16}>
          <CountryReport />
        </Box>
      )}
      {active === 3 && (
        <Box flex={1} ml={16}>
          <RegionReport />
        </Box>
      )}
      {active === 4 && (
        <Box flex={1} ml={16}>
          City | work in progress
        </Box>
      )}
    </Flex>
  );
}
