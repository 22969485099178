export enum AppRoutes {
    HOME = "/",
    JOB_SEARCH = "/jobs",
    JOB = "job",
    COMPLETE_APPLICATION = "/complete-application",
    SIGNUP = "/signup",
    LOGIN = "/login",
    DASHBOARD = "/dashboard",
    ADMIN_DASHBOARD = "/admin-dashboard",
}
