export class StringUtils {

    static toCaptilize(str: string) {
        if (str) {
            return `${str[0].toUpperCase()}${str.substring(1).toLowerCase()}`;
        }
        return "";
    }

    static sanitizeRole(role: string): string {
        role = role.replace('_', ' ');
        return StringUtils.toCaptilize(role);
    }

    static stripHtmlTags(input: string): string {
        // Create a temporary DOM element to parse the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = input;

        // Use the textContent property to get the text without HTML tags
        let textContent = tempDiv.textContent || '';

        // Remove extra spaces between words
        textContent = textContent.replace(/\s+/g, ' ').trim();

        // Properly handle casing
        textContent = textContent.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, (c) => c.toUpperCase());

        return textContent;
    }

    static formatNumber(num: number): string {
        if (num < 1000) {
            return num.toString();
        } else if (num >= 1000 && num < 1_000_000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
        } else if (num >= 1_000_000 && num < 1_000_000_000) {
            return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
        } else if (num >= 1_000_000_000 && num < 1_000_000_000_000) {
            return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
        } else {
            return (num / 1_000_000_000_000).toFixed(1).replace(/\.0$/, '') + 'T';
        }
    }
}