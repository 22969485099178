import { Auth, createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, UserCredential } from 'firebase/auth';
import { AuthConnector } from '../serverConnectors';
import { HttpStatusCode } from 'axios';
import { AuthServiceResponse, Roles, User } from '../types';
import store from '../store/store';
import { setUser } from '../store/authSlice';
import { notifications } from '@mantine/notifications';

const authConnector: AuthConnector = new AuthConnector();

export const AuthService = {

    doSignupWithGoogle: async (role: Roles): Promise<User | null> => {
        let user: User | null = null;
        let userAuthServiceResponse: AuthServiceResponse<User>;

        try {
            const auth = getAuth();
            const provider = new GoogleAuthProvider();

            provider.addScope('https://www.googleapis.com/auth/admin.directory.userschema.readonly');

            const userCredential: UserCredential = await signInWithPopup(auth, provider);
            const idToken = await userCredential.user.getIdToken();
            const response = await authConnector.axios.post('/user/register', {
                uid: userCredential.user.uid,
                name: userCredential.user.displayName,
                email: userCredential.user.email,
                profilePicture: userCredential.user.photoURL,
                idToken: idToken,
                role: role,
            });

            userAuthServiceResponse = response.data;

            if (userAuthServiceResponse.error) {
                throw new Error(userAuthServiceResponse.message);
            }
            else {
                user = userAuthServiceResponse.success;
            }

            sessionStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('token', idToken);
            sessionStorage.setItem('firebaseUser', JSON.stringify(userCredential.user));
            store.dispatch(setUser(user));
        }
        catch (error: any) {
            notifications.show({
                title: 'Error with Google signup',
                message: error.message,
                color: 'red',
                position: 'bottom-left',
                autoClose: false,
            });
            console.error(`Error AuthService::doGoogleSSO: ${error.message}`);
        }

        return user;
    },

    doLoginWithGoogle: async (): Promise<User | null> => {
        let user: User | null = null;
        let userAuthServiceResponse: AuthServiceResponse<User>;

        try {
            const auth = getAuth();
            const provider = new GoogleAuthProvider();

            provider.addScope('https://www.googleapis.com/auth/admin.directory.userschema.readonly');

            const userCredential: UserCredential = await signInWithPopup(auth, provider);
            const idToken = await userCredential.user.getIdToken();
            const response = await authConnector.axios.post('/user/login', {
                uid: userCredential.user.uid,
                email: userCredential.user.email,
                idToken: idToken,
            });

            userAuthServiceResponse = response.data;

            if (userAuthServiceResponse.error) {
                throw new Error(userAuthServiceResponse.message);
            }
            else {
                user = userAuthServiceResponse.success;
            }

            sessionStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('token', idToken);
            sessionStorage.setItem('firebaseUser', JSON.stringify(userCredential.user));
            store.dispatch(setUser(user));
        }
        catch (error: any) {
            notifications.show({
                title: 'Error with Google signup',
                message: error.message,
                color: 'red',
                position: 'bottom-left',
                autoClose: false,
            });
            console.error(`Error AuthService::doGoogleSSO: ${error.message}`);
        }

        return user;
    },

    doSignupWithEmailAndPassword: async (email: string, password: string, role: Roles): Promise<User | null> => {
        let user: User | null = null;
        let userAuthServiceResponse: AuthServiceResponse<User>;

        try {
            const auth = getAuth();
            const userCredential: UserCredential = await createUserWithEmailAndPassword(auth, email, password);
            const idToken = await userCredential.user.getIdToken();
            const response = await authConnector.axios.post('/user/register', {
                uid: userCredential.user.uid,
                name: userCredential.user.displayName,
                email: userCredential.user.email,
                profilePicture: userCredential.user.photoURL,
                idToken: idToken,
                role: role,
            });

            userAuthServiceResponse = response.data;

            if (userAuthServiceResponse.error) {
                throw new Error(userAuthServiceResponse.message);
            }
            else {
                user = userAuthServiceResponse.success;
            }

            sessionStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('token', idToken);
            sessionStorage.setItem('firebaseUser', JSON.stringify(userCredential.user));
            store.dispatch(setUser(user));
        }
        catch (error: any) {
            notifications.show({
                title: 'Error with email signup',
                message: error.message,
                color: 'red',
                position: 'bottom-left',
                autoClose: false,
            });
            console.error(`Error AuthService::doSignupWithEmailAndPassword: ${error.message}`);
        }
        return user;
    },

    doLoginWithEmailAndPassword: async (email: string, password: string): Promise<User | null> => {
        let user: User | null = null;
        let userAuthServiceResponse: AuthServiceResponse<User>;

        try {
            const auth = getAuth();
            const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
            const idToken = await userCredential.user.getIdToken();
            const response = await authConnector.axios.post('/user/login', {
                uid: userCredential.user.uid,
                email: userCredential.user.email,
                idToken: idToken,
            });

            userAuthServiceResponse = response.data;

            if (userAuthServiceResponse.error) {
                throw new Error(userAuthServiceResponse.message);
            }
            else {
                user = userAuthServiceResponse.success;
            }

            sessionStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('token', idToken);
            sessionStorage.setItem('firebaseUser', JSON.stringify(userCredential.user));
            store.dispatch(setUser(user));
        }
        catch (error: any) {
            notifications.show({
                title: 'Error with email login',
                message: error.message,
                color: 'red',
                position: 'bottom-left',
                autoClose: false,
            });
            console.error(`Error AuthService::doLoginWithEmailAndPassword: ${error.message}`);
        }
        return user;
    },

    logout: async (): Promise<void> => {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('firebaseUser');
        store.dispatch(setUser(null));
    }
}