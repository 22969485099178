import {
  Button,
  Divider,
  Flex,
  Loader,
  PasswordInput,
  rem,
  Text,
  TextInput,
} from "@mantine/core";
import { ReactComponent as IconLoginPage } from "../../assets/iconSignUpPage.svg";
import { ReactComponent as IconGoogle } from "../../assets/iconGoogle.svg";
import { AppRoutes, Fonts } from "../../models";
import { useMediaQuery } from "@mantine/hooks";
import { AuthValidation, ScreenSize } from "../../utils";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../services/authService";
import { User } from "../../types";
import { useForm } from "@mantine/form";
import { useState } from "react";

export function LoginPage(props: {}) {
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const loginForm = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value: string) => AuthValidation.validateEmail(value),
      password: (value: string) => AuthValidation.validatePassword(value),
    },
  });

  async function doLoginViaGoogle() {
    const user: User | null = await AuthService.doLoginWithGoogle();
    if (user) {
      navigate(`${AppRoutes.DASHBOARD}`);
    }
  }

  async function doLoginViaEmailAndPassword() {
    setLoading(true);
    const email: string = loginForm.getTransformedValues().email;
    const password: string = loginForm.getTransformedValues().password;

    const user: User | null = await AuthService.doLoginWithEmailAndPassword(email, password);
    if (user) {
      navigate(`${AppRoutes.DASHBOARD}`);
    }
    setLoading(false);
  }

  return (
    <Flex w={"100%"} p={32}>
      <Flex w={"100%"} align={"center"} p={16} direction={"column"}>
        <Flex direction={"column"} w={"100%"} align={"center"}>
          <Text
            ff={Fonts.NEWSREADER}
            style={{
              fontSize: isDesktop ? rem(48) : isTab ? rem(40) : rem(32),
            }}
            fw={500}
          >
            Welcome Back!
          </Text>
          <Text size="sm" fw={500} c={"#4d4d4d"}>
            We have missed you a lot, we hope you too!
          </Text>
        </Flex>
        <Flex
          bg={"#efefef"}
          p={32}
          my={16}
          style={{ borderRadius: 8 }}
          direction={"column"}
          w={isDesktop ? "55%" : isTab ? "50%" : "100%"}
        >
          <form onSubmit={loginForm.onSubmit(doLoginViaEmailAndPassword)}>
            <TextInput
              label="Email"
              placeholder="Enter your email"
              my={8}
              w={"100%"}
              {...loginForm.getInputProps('email')}
            />
            <PasswordInput
              label="Password"
              type="password"
              placeholder="Enter your password"
              my={8}
              w={"100%"}
              {...loginForm.getInputProps('password')}
            />

            <Flex justify={"flex-end"} my={8} style={{ cursor: "pointer" }}>
              <Text td={"underline"} c={"#4d4d4d"} size={isMobile ? "sm" : "md"}>
                Forgot Password?
              </Text>
            </Flex>

            <Button color="black" my={8} type="submit" disabled={loading} w={"100%"}>
              {loading && <Loader />}
              <Text size="sm">LOG IN</Text>
            </Button>
          </form>

          <Flex justify={"center"} my={4}>
            <Text size="sm" mr={4}>
              Do not have an account?
            </Text>
            <Text
              size="sm"
              fw={500}
              td={"underline"}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`${AppRoutes.SIGNUP}`)}
            >
              Sign Up
            </Text>
          </Flex>

          <Divider my={32} />

          <Button
            bg={"#ffffff"}
            c={"#000000"}
            my={8}
            onClick={doLoginViaGoogle}
            disabled={loading}
          >
            <Flex align={"center"}>
              <IconGoogle />
              <Text size="sm" ml={4}>
                Continue with Google
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      {isDesktop && (
        <Flex w={"100%"}>
          <IconLoginPage />
        </Flex>
      )}
    </Flex>
  );
}
