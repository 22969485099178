import {
  Drawer,
  Flex,
  MultiSelect,
  NativeSelect,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { JobAdminDto } from "../../types/jobs/jobAdminDto";
import MonacoEditor from "@monaco-editor/react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { City, Country, Region } from "../../types";
import { useEffect } from "react";
import { LocationResolverService } from "../../services";

export function JobEditor(props: {
  opened: boolean;
  close: () => void;
  job: JobAdminDto | null;
}) {
  const countries = useSelector(
    (state: RootState) => state.adminDashboardReducer.countries,
  );
  const regions = useSelector(
    (state: RootState) => state.adminDashboardReducer.regions,
  );
  const cities = useSelector(
    (state: RootState) => state.adminDashboardReducer.cities,
  );

  const locationResolverService: LocationResolverService =
    new LocationResolverService();

  useEffect(() => {
    locationResolverService.getAllRegions(props.job?.country || "");
    locationResolverService.getAllCities(props.job?.city || "");
  }, [props.job]);

  return (
    <Drawer
      opened={props.opened}
      onClose={props.close}
      overlayProps={{ backgroundOpacity: 0.2, blur: 4 }}
      transitionProps={{
        duration: 300,
      }}
      size={"xl"}
      position="right"
      offset={8}
      styles={{
        content: {
          height: "250",
          borderRadius: 16,
        },
        overlay: {
          height: "250",
        },
      }}
      title={props.job ? props.job.title : "Create a new job"}
    >
      {!props.job && (
        <Text ta="center" size="xl">
          Create a new job
        </Text>
      )}

      {props.job && (
        <Flex direction={"column"}>
          <TextInput
            w={"100%"}
            my={4}
            label="Title"
            defaultValue={props.job.title}
          />
          <TextInput
            w={"100%"}
            my={4}
            label="Jobsrmine url"
            defaultValue={props.job.jobsrmine_url}
          />
          <TextInput
            w={"100%"}
            my={4}
            label="Application url"
            defaultValue={props.job.application_url}
          />
          <TextInput
            w={"100%"}
            my={4}
            label="Sender reference"
            defaultValue={props.job.sender_reference}
          />
          <TextInput
            w={"100%"}
            my={4}
            label="Display reference"
            defaultValue={props.job.display_reference}
          />
          <Select
            label={"Country"}
            data={countries.map((c: Country) => c.countryName)}
            defaultValue={props.job.country}
            searchable
            my={4}
          />
          <Select
            label={"Country code"}
            data={countries.map((c: Country) => c.code)}
            defaultValue={props.job.country_code}
            searchable
            my={4}
          />
          <Select
            label={"Region"}
            data={regions.map((r: Region) => r.regionName)}
            defaultValue={props.job.region}
            searchable
            my={4}
          />
          <Select
            label={"City"}
            data={cities.map((c: City) => c.cityName)}
            defaultValue={props.job.city}
            my={4}
          />

          <Flex h={400} direction={"column"} my={4}>
            <Text size="sm" fw={500} mb={4}>
              Job description
            </Text>
            <MonacoEditor
              height="100%"
              language="html"
              value={props.job.description}
              options={{
                minimap: { enabled: false },
                wordWrap: "on",
                wrappingIndent: "same",
                wrappingStrategy: "advanced",
              }}
            />
          </Flex>
        </Flex>
      )}
    </Drawer>
  );
}
