import {
    Flex,
    Loader,
    Select,
    Skeleton,
    Table,
    Text,
    TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { LocationResolverService } from "../../services";
import { Country, Region } from "../../types";

export function RegionReport(props: {}) {
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const locationResolverService: LocationResolverService =
    new LocationResolverService();

  const [countries, setCountries] = useState<Country[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [filteredRegions, setFilteredRegions] = useState<Region[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      getAllRegions(selectedCountry);
    }
  }, [selectedCountry]);

  async function getAllCountries() {
    setLoading(true);
    const countries = await locationResolverService.getAllCountries();
    setCountries(countries);
    setLoading(false);
  }

  async function getAllRegions(country: Country) {
    setLoading(true);
    const regions = await locationResolverService.getAllRegions(
      country.countryName,
    );
    setRegions(regions);
    setFilteredRegions(regions);
    setLoading(false);
  }

  function filterRegions(searchKeyword: string) {
    setLoading(true);
    console.log("searchKeyword: ", searchKeyword);
    let filteredRegions: Region[] = [];
    if (searchKeyword.length != 0) {
      filteredRegions = regions.filter(
        (region) =>
          region.regionName
            .toLowerCase()
            .includes(searchKeyword.toLowerCase())
      );
    } else {
      filteredRegions = regions;
    }
    setFilteredRegions(filteredRegions);
    setLoading(false);
  }

  const rows = filteredRegions.map((region) => (
    <Table.Tr key={region.id}>
      <Table.Td>{region.id}</Table.Td>
      <Table.Td>{region.regionName}</Table.Td>
      <Table.Td>{region.country.countryName}</Table.Td>
      <Table.Td>{region.acronym}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Flex px={12} direction={"column"}>
      <Text fw={500} mb={8}>
        Region Report
      </Text>

      <form>
        <Flex>
          <Select
            disabled={loading}
            w={300}
            searchable
            label={"Country"}
            placeholder="Select Country"
            data={countries.map((country: Country) => country.countryName)}
            onChange={(value: any) => {
              setSelectedCountry(
                countries.find((country) => country.countryName === value),
              );
            }}
            rightSection={loading && <Loader size="xs" />}
            mr={8}
          />
          <TextInput
            w={300}
            label={"Search Region"}
            size="sm"
            placeholder="Region name"
            onChange={(event) => {
              filterRegions(event.target.value);
            }}
            mr={8}
          />
        </Flex>
      </form>

      <Table
        my={16}
        striped
        stickyHeader
        stickyHeaderOffset={10}
        highlightOnHover
      >
        <Table.Thead bg={"black"} c={"white"}>
          <Table.Tr>
            <Table.Th>Region Id</Table.Th>
            <Table.Th>Region Name</Table.Th>
            <Table.Th>Country</Table.Th>
            <Table.Th>Acronym</Table.Th>
          </Table.Tr>
        </Table.Thead>
        {loading && (
          <Table.Tbody>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
              <Table.Tr key={index}>
                <Table.Td colSpan={8}>
                  <Skeleton height={20} my={2} width={"100%"} />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        )}
        {!loading && rows.length > 0 && <Table.Tbody>{rows}</Table.Tbody>}
        {!loading && rows.length === 0 && (
          <Table.Tbody>
            <Table.Tr>
              <Table.Td colSpan={8} h={200}>
                <Text ta={"center"} fw={500}>
                  No region data found, select country to view regions.
                </Text>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        )}
      </Table>
    </Flex>
  );
}
