import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { City, Country, Region } from "../types";

export type AdminDashboardSlice = {
  countries: Country[];
  regions: Region[];
  cities: City[];
  industries: string[];

  searchFilter: {
    currentSearchKeyword: string;
    selectedIndustries: string[];
    selectedAdvertisers: string[];
    selectedJobTypes: string[];
    selectedCountries: string[];
    selectedRegions: string[];
    selectedCities: string[];
  };
};

const adminDashboardSliceState: AdminDashboardSlice = {
  countries: [],
  regions: [],
  cities: [],
  industries: [],

  searchFilter: {
    currentSearchKeyword: "",
    selectedIndustries: [],
    selectedAdvertisers: [],
    selectedJobTypes: [],
    selectedCountries: [],
    selectedRegions: [],
    selectedCities: [],
  },
};

export const adminDashboardSlice = createSlice({
  name: "adminDashboardSlice",

  initialState: adminDashboardSliceState,
  reducers: {
    setCountries(state: AdminDashboardSlice, action: PayloadAction<Country[]>) {
      state.countries = action.payload;
      return state;
    },

    setRegions(state: AdminDashboardSlice, action: PayloadAction<Region[]>) {
      state.regions = action.payload;
      return state;
    },

    setCities(state: AdminDashboardSlice, action: PayloadAction<City[]>) {
      state.cities = action.payload;
      return state;
    },

    setIndustries(state: AdminDashboardSlice, action: PayloadAction<string[]>) {
      state.industries = action.payload;
      return state;
    },

    setCurrentSearchKeyword(
      state: AdminDashboardSlice,
      action: PayloadAction<string>,
    ) {
      state.searchFilter.currentSearchKeyword = action.payload;
      return state;
    },
    setSelectedIndustries(
      state: AdminDashboardSlice,
      action: PayloadAction<string[]>,
    ) {
      state.searchFilter.selectedIndustries = action.payload;
      return state;
    },
    setSelectedAdvertisers(
      state: AdminDashboardSlice,
      action: PayloadAction<string[]>,
    ) {
      state.searchFilter.selectedAdvertisers = action.payload;
      return state;
    },
    setSelectedJobType(
      state: AdminDashboardSlice,
      action: PayloadAction<string[]>,
    ) {
      state.searchFilter.selectedJobTypes = action.payload;
      return state;
    },
    setSelectedCountries(
      state: AdminDashboardSlice,
      action: PayloadAction<string[]>,
    ) {
      state.searchFilter.selectedCountries = action.payload;
      return state;
    },
    setSelectedRegions(
      state: AdminDashboardSlice,
      action: PayloadAction<string[]>,
    ) {
      state.searchFilter.selectedRegions = action.payload;
      return state;
    },
    setSelectedCities(
      state: AdminDashboardSlice,
      action: PayloadAction<string[]>,
    ) {
      state.searchFilter.selectedCities = action.payload;
      return state;
    },
    resetAdminDashboardSlice(state: AdminDashboardSlice) {
      state.countries = [];
      state.regions = [];
      state.cities = [];
      return state;
    },
  },
});

export const {
  setCountries,
  setRegions,
  setCities,
  setIndustries,
  setCurrentSearchKeyword,
  setSelectedIndustries,
  setSelectedAdvertisers,
  setSelectedJobType,
  resetAdminDashboardSlice,
  setSelectedCountries,
  setSelectedRegions,
  setSelectedCities,
} = adminDashboardSlice.actions;
export default adminDashboardSlice.reducer;
