import { configureStore } from "@reduxjs/toolkit";
import searchResultPageReducer from "./searchResultPageSlice";
import userMetaDataReducer from "./userMetaDataSlice";
import redirectPageReducer from "./redirectPageSlice";
import searchFilterReducer from "./searchFilterSlice";
import authReducer from "./authSlice";
import adminDashboardReducer from "./adminDashboardSlice";

const store = configureStore({
  reducer: {
    searchFilter: searchFilterReducer,
    searchResultPage: searchResultPageReducer,
    userMetaData: userMetaDataReducer,
    redirectPage: redirectPageReducer,
    auth: authReducer,
    adminDashboardReducer: adminDashboardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
