import {
  Flex,
  Space,
  Button,
  Text,
  Drawer,
  Avatar,
  Menu,
  rem,
} from "@mantine/core";
import { ReactComponent as JrmIcon } from "../../assets/icon.svg";
import {
  IconBookmarkFilled,
  IconHome,
  IconLock,
  IconLogin,
  IconLogout,
  IconMail,
  IconMenuDeep,
} from "@tabler/icons-react";
import { AppRoutes } from "../../models";
import styles from "./navbar.module.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { ScreenSize, StringUtils } from "../../utils";
import { User } from "../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { AuthService } from "../../services/authService";

export function Navbar() {
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [hover, setHover] = useState<string>();
  const [highlighted, setHighlighted] = useState<string>("");
  const navigate = useNavigate();

  const user: User | null = useSelector((state: RootState) => state.auth.user);

  async function logout() {
    await AuthService.logout();
    setExpanded(false);
    navigate(AppRoutes.HOME);
  }

  return (
    <Flex w={"100%"} justify={"center"}>
      <Flex
        py={20}
        style={{ fontFamily: "Inter" }}
        justify={"space-between"}
        w={"70%"}
      >
        <Flex>
          <JrmIcon
            onClick={() => navigate(AppRoutes.HOME)}
            style={{ cursor: "pointer" }}
          />
          <Space w={30} />
        </Flex>
        {!user && (
          <Flex align={"center"} className={styles.navResponsive}>
            <IconBookmarkFilled />
            <Space w={30} />
            <Button
              color="black"
              w={100}
              h={45}
              radius={"sm"}
              variant="default"
              style={{ border: "2px solid black" }}
              onClick={() => navigate(`${AppRoutes.LOGIN}`)}
            >
              <Text size="sm">LOGIN</Text>
            </Button>
            <Space w={30} />
            <Button
              color="black"
              w={100}
              h={45}
              radius={"sm"}
              onClick={() => navigate(`${AppRoutes.SIGNUP}`)}
            >
              <Text size="sm">SIGN UP</Text>
            </Button>
          </Flex>
        )}
        {user && (
          <Flex align={"center"} className={styles.navResponsive}>
            <Text size="sm">{user.email}</Text>
            <Space w={12} />
            <Menu trigger="hover" openDelay={100} closeDelay={400} width={200}>
              <Menu.Target>
                <Avatar key={user.email} name={user.email} color="initials" />
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>{user.email}</Menu.Label>
                <Menu.Label>
                  {StringUtils.sanitizeRole(user.role.name)}
                </Menu.Label>
                <Menu.Item
                  leftSection={<IconHome size={14} />}
                  onClick={() => navigate(AppRoutes.DASHBOARD)}
                >
                  Dashboard
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconLogout size={14} />}
                  onClick={logout}
                >
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        )}
        <Flex
          className={styles.navResponsiveIcon}
          align={"center"}
          justify={"center"}
          onClick={() => setExpanded(!expanded)}
        >
          <IconMenuDeep />
        </Flex>
      </Flex>

      <Drawer
        opened={expanded}
        onClose={() => setExpanded(false)}
        position={"right"}
        overlayProps={{ backgroundOpacity: 0.2, blur: 4 }}
        transitionProps={{
          duration: 300,
        }}
        styles={{
          content: {
            height: "250",
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
          },
          overlay: {
            height: "250",
          },
        }}
      >
        <Flex direction={"column"}>
          {!user && (
            <Flex
              p={6}
              my={2}
              onClick={() => {
                setExpanded(false);
                navigate(`${AppRoutes.LOGIN}`);
              }}
              style={{ cursor: "pointer" }}
              align={"center"}
            >
              <IconLogin />
              <Text size="lg" fw={500} ml={8}>
                Login
              </Text>
            </Flex>
          )}
          {!user && (
            <Flex
              p={6}
              my={2}
              onClick={() => {
                setExpanded(false);
                navigate(`${AppRoutes.LOGIN}`);
              }}
              style={{ cursor: "pointer" }}
              align={"center"}
            >
              <IconLock />
              <Text size="lg" fw={500} ml={8}>
                Signup
              </Text>
            </Flex>
          )}
          {user && (
            <Flex direction={"column"}>
              <Flex
                style={{ cursor: "pointer" }}
                align={"center"}
                justify={"center"}
              >
                <Avatar
                  key={user.email}
                  name={user.email}
                  color="initials"
                  size={"xl"}
                  mr={6}
                />
              </Flex>
              <Space h={12} />
              {[
                {
                  link: "",
                  text: user.email,
                  onClick: () => {},
                  icon: IconMail,
                },
                {
                  link: AppRoutes.DASHBOARD,
                  text: "Dashboard",
                  onClick: () => {
                    navigate(AppRoutes.DASHBOARD);
                    setExpanded(false);
                  },
                  icon: IconHome,
                },
                {
                  link: AppRoutes.HOME,
                  text: "Logout",
                  onClick: () => {
                    logout();
                    setExpanded(false);
                  },
                  icon: IconLogout,
                },
              ].map((item) => {
                return (
                  <JRMLink
                    link={item.link}
                    text={item.text}
                    onClick={item.onClick}
                    icon={item.icon}
                  />
                );
              })}
            </Flex>
          )}
        </Flex>
      </Drawer>
    </Flex>
  );
}

function JRMLink(props: {
  link: string;
  text: string;
  onClick: () => void;
  icon: any;
}) {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <Flex
      align={"center"}
      w={"100%"}
      bg={hover ? "#efefef" : "#ffffff"}
      p={8}
      my={2}
      style={{ cursor: "pointer" }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={props.onClick}
    >
      <props.icon style={{ width: rem(20), height: rem(20) }} />
      <Text ml={6} size="sm" c={"#4d4d4d"}>
        {props.text}
      </Text>
    </Flex>
  );
}
