import { HttpStatusCode } from "axios";
import { LocationApiConnector } from "../serverConnectors";
import { setCities, setCountries, setRegions } from "../store";
import store from "../store/store";
import { City, Country, Location, Region } from "../types";

export class LocationResolverService extends LocationApiConnector {
  async resolveIpToCountry(ipAddress: string): Promise<Country | null> {
    let country: Country | null = null;
    try {
      const response = await this.axios.get(`/ip?ip=${ipAddress}`);
      if (response.status === HttpStatusCode.Ok) {
        country = response.data;
      }
    } catch (error: any) {
      console.log("Error: ", error);
    }
    return country;
  }

  async getClientAvailableLocations(countryName: string): Promise<Location[]> {
    let locationSuggestions: Location[] = JSON.parse(
      localStorage.getItem("availableLocations") || "[]",
    );
    if (locationSuggestions.length > 0) {
      return locationSuggestions;
    }
    try {
      const response = await this.axios.post("/city", {
        countryName: countryName,
        cityName: "",
      });
      if (response.status === HttpStatusCode.Ok) {
        locationSuggestions = response.data;
      }
      if (localStorage.getItem("availableLocations") === null) {
        localStorage.setItem(
          "availableLocations",
          JSON.stringify(locationSuggestions),
        );
      }
    } catch (error: any) {
      console.log("Error: ", error);
    }
    return locationSuggestions;
  }

  async searchCityByName(
    countryName: string,
    regionName: string,
    cityName: string,
  ): Promise<City | null> {
    let city: City | null = null;
    try {
      const response = await this.axios.get(
        `/city?city=${cityName}&region=${regionName}&country=${countryName}`,
      );
      if (response.status === HttpStatusCode.Ok) {
        console.log("SearchCityByName: ", response.data);
        city = response.data;
      }
    } catch (error: any) {
      console.log("Error: ", error);
    }
    return city;
  }

  async getAllCountries(): Promise<Country[]> {
    let countries: Country[] = [];
    try {
      const response = await this.axios.get("/country/all");
      if (response.status === HttpStatusCode.Ok) {
        countries = response.data;
        store.dispatch(setCountries(countries));
      }
    } catch (error: any) {
      console.log("Error: ", error);
    }
    return countries;
  }

  async getAllRegions(countryName: string): Promise<Region[]> {
    let regions: Region[] = [];
    try {
      const response = await this.axios.get(`/region?country=${countryName}`);
      if (response.status === HttpStatusCode.Ok) {
        regions = response.data;
        store.dispatch(setRegions(regions));
      }
    } catch (error: any) {
      console.log("Error: ", error);
    }
    return regions;
  }

  async getAllCities(countryName: string): Promise<City[]> {
    let cities: City[] = [];
    try {
      const response = await this.axios.post("/city", {
        countryName: countryName,
        cityName: "",
      });
      if (response.status === HttpStatusCode.Ok) {
        cities = response.data;
        store.dispatch(setCities(cities));
      }
    } catch (error: any) {
      console.log("Error: ", error);
    }
    return cities
  }
}
