import { useSelector } from "react-redux";
import { Roles, User } from "../../types";
import { RootState } from "../../store";
import { Flex, Image, Text } from "@mantine/core";
import { StringUtils } from "../../utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../models";

export function DashboardPage() {
  const user: User | null = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(AppRoutes.LOGIN);
      return;
    }
    if (user.role.name === Roles.ADMIN) {
      navigate(AppRoutes.ADMIN_DASHBOARD);
      return;
    }
  }, [user])

  return (
    <>
      {user && (
        <Flex
          direction="column"
          justify={"center"}
          align={"center"}
          m={16}
          p={16}
        >
          <Text size="xl" fw={500} mb={4}>Welcome!</Text>
          <Image
            src={user.profilePicture}
            alt={user.name}
            style={{ borderRadius: "50%" }}
            w={100}
          />
          <Text fw={500} mt={4}>{user.email}</Text>
          <Text fw={500} mt={4}>{StringUtils.sanitizeRole(user.role.name)}</Text>

        </Flex>
      )}
    </>
  );
}
