import {
  Anchor,
  Button,
  Checkbox,
  Divider,
  Flex,
  Group,
  Loader,
  PasswordInput,
  Radio,
  rem,
  Text,
  TextInput,
} from "@mantine/core";
import { ReactComponent as IconSignUpPage } from "../../assets/iconSignUpPage.svg";
import { ReactComponent as IconGoogle } from "../../assets/iconGoogle.svg";
import { AppRoutes, Fonts } from "../../models";
import { useMediaQuery } from "@mantine/hooks";
import { AuthValidation, ScreenSize } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { Roles, User } from "../../types";
import { AuthService } from "../../services/authService";
import { notifications } from "@mantine/notifications";
import { useState } from "react";

export function SignUpPage(props: {}) {
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const signupForm = useForm({
    initialValues: {
      email: "",
      password: "",
      role: Roles.JOB_SEEKER,
      agree: false,
    },
    validate: {
      email: (value: string) => AuthValidation.validateEmail(value),
      password: (value: string) => AuthValidation.validatePassword(value),
      role: (value: string) => AuthValidation.roleValidation(value),
      agree: (value: boolean) => AuthValidation.validateAgree(value),
    },
  });

  async function doSignupViaEmailAndPassword() {
    setLoading(true);
    const email: string = signupForm.getTransformedValues().email;
    const password: string = signupForm.getTransformedValues().password;
    const role: Roles = signupForm.getTransformedValues().role;
    const agree: boolean = signupForm.getTransformedValues().agree;

    const user: User | null = await AuthService.doSignupWithEmailAndPassword(
      email,
      password,
      role
    );
    if (user) {
      navigate(`${AppRoutes.DASHBOARD}`);
    }

    console.log({ email, password, role, agree });
    setLoading(false);
  }

  async function doSignupViaGoogle() {
    setLoading(true);
    const user: User | null = await AuthService.doSignupWithGoogle(Roles.JOB_SEEKER);
    if (user) {
      navigate(`${AppRoutes.DASHBOARD}`);
    }
    setLoading(false);
  }

  return (
    <Flex w={"100%"} p={32}>
      <Flex w={"100%"} align={"center"} p={16} direction={"column"}>
        <Flex direction={"column"} w={"100%"} align={"center"}>
          <Text
            ff={Fonts.NEWSREADER}
            style={{
              fontSize: isDesktop ? rem(48) : isTab ? rem(40) : rem(32),
            }}
            fw={500}
          >
            Start your journey now!
          </Text>
          <Text size="sm" fw={500} c={"#4d4d4d"}>
            At jobsrmine, we provide job seekers and employers with a fast and
            efficient
            <br />
            approach for job hunting and job posting.
          </Text>
        </Flex>

        <Flex
          bg={"#efefef"}
          p={32}
          my={16}
          style={{ borderRadius: 8 }}
          direction={"column"}
          w={isDesktop ? "55%" : isTab ? "50%" : "100%"}
        >
          <form onSubmit={signupForm.onSubmit(doSignupViaEmailAndPassword)}>

            <TextInput
              label="Email"
              placeholder="Enter your email"
              my={8}
              w={"100%"}
              {...signupForm.getInputProps("email")}
            />
            <PasswordInput
              label="Password"
              type="password"
              placeholder="Enter your password"
              my={8}
              w={"100%"}
              {...signupForm.getInputProps("password")}
            />

            <Radio.Group my={8} {...signupForm.getInputProps("role")}>
              <Flex>
                <Radio label="Job seeker" value={Roles.JOB_SEEKER} mr={16} />
                <Radio label="Advertiser" value={Roles.ADVERTISER} />
              </Flex>
            </Radio.Group>

            <Checkbox
              my={8}
              label="I agree to the terms and conditions"
              {...signupForm.getInputProps("agree")}
            />

            <Button color="black" my={8} type="submit" disabled={loading} w={"100%"}>
              {loading && <Loader />}
              <Text size="sm">SIGN UP</Text>
            </Button>
          </form>
          <Flex justify={"center"} my={4}>
            <Text size="sm" mr={4}>
              Have an account?
            </Text>
            <Text
              size="sm"
              fw={500}
              td={"underline"}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`${AppRoutes.LOGIN}`)}
            >
              Log in
            </Text>
          </Flex>

          <Divider my={32} />

          <Button
            bg={"#ffffff"}
            c={"#000000"}
            my={8}
            onClick={() => doSignupViaGoogle()}
            disabled={loading}
          >
            <Flex align={"center"}>
              <IconGoogle />
              <Text size="sm" ml={4}>
                Sign up with Google
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      {isDesktop && (
        <Flex w={"100%"}>
          <IconSignUpPage />
        </Flex>
      )}
    </Flex>
  );
}
