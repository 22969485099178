import {
  Button,
  Flex,
  Loader,
  Pagination,
  Select,
  Skeleton,
  Space,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MAX_SALARY } from "../../models";
import { JobService, LocationResolverService } from "../../services";
import {
  RootState,
  setCurrentSearchKeyword,
  setSelectedAdvertisers,
  setSelectedCountries,
  setSelectedIndustries
} from "../../store";
import store from "../../store/store";
import { Country } from "../../types";
import { JobAdminDto } from "../../types/jobs/jobAdminDto";
import { CustomMultiSelect } from "../customMultiSelect/customMultiSelect.component";
import { JobEditor } from "../jobEditor/jobEditor.component";

export function AdminJobsReport(props: {}) {
  const [jobAdminDtos, setJobAdminDtos] = useState<JobAdminDto[]>([]);
  const [totalJobCount, setTotalJobsCount] = useState<number>(0);
  const countries = useSelector(
    (state: RootState) => state.adminDashboardReducer.countries,
  );
  const regions = useSelector(
    (state: RootState) => state.adminDashboardReducer.regions,
  );
  const cities = useSelector(
    (state: RootState) => state.adminDashboardReducer.cities,
  );
  const industries = useSelector(
    (state: RootState) => state.adminDashboardReducer.industries,
  );

  const currentSearchKeyword: string = useSelector(
    (state: RootState) =>
      state.adminDashboardReducer.searchFilter.currentSearchKeyword,
  );
  const selectedIndusties: string[] = useSelector(
    (state: RootState) =>
      state.adminDashboardReducer.searchFilter.selectedIndustries,
  );
  const selectedAdvertisers: string[] = useSelector(
    (state: RootState) =>
      state.adminDashboardReducer.searchFilter.selectedAdvertisers,
  );
  const selectedJobTypes: string[] = useSelector(
    (state: RootState) =>
      state.adminDashboardReducer.searchFilter.selectedJobTypes,
  );
  const selectedCountries: string[] = useSelector(
    (state: RootState) =>
      state.adminDashboardReducer.searchFilter.selectedCountries,
  );
  const selectedRegions: string[] = useSelector(
    (state: RootState) =>
      state.adminDashboardReducer.searchFilter.selectedRegions,
  );
  const selectedCities: string[] = useSelector(
    (state: RootState) =>
      state.adminDashboardReducer.searchFilter.selectedCities,
  );
  const clientCountry = useSelector(
    (state: RootState) => state.userMetaData.clientCountry,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const pageSize: number = 100;
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [timeTaken, setTimeTaken] = useState<number>(0);
  const [jobEditorOpened, setJobEditorOpened] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<JobAdminDto | null>(null);

  const jobService: JobService = new JobService();
  const locationResolverService: LocationResolverService =
    new LocationResolverService();

  const rows = jobAdminDtos.map((job) => (
    <Table.Tr
      key={job.id}
      style={{ cursor: "pointer" }}
      onClick={() => {
        setSelectedJob(job);
        setJobEditorOpened(true);
      }}
    >
      <Table.Td>{job.sender_reference}</Table.Td>
      <Table.Td>{job.title}</Table.Td>
      <Table.Td>{job.category}</Table.Td>
      <Table.Td>{job.job_type}</Table.Td>
      <Table.Td>{job.employment_type}</Table.Td>
      <Table.Td>{job.country}</Table.Td>
      <Table.Td>{job.region}</Table.Td>
      <Table.Td>{job.city}</Table.Td>
    </Table.Tr>
  ));

  useEffect(() => {
    getJobsForAdmin();
  }, [activePage]);

  useEffect(() => {
    if (selectedCountries.length) {
      locationResolverService.getAllRegions(selectedCountries[0]);
    }
  }, [selectedCountries]);

  async function getJobsForAdmin() {
    setLoading(true);
    setJobAdminDtos([]);

    const startTime = new Date().getTime();

    const response = await jobService.adminJobSearch({
      keyword: currentSearchKeyword,
      countries: selectedCountries.length
        ? selectedCountries
        : [clientCountry ? clientCountry.countryName : ""],
      regions: selectedRegions,
      cities: selectedCities,
      categories: selectedIndusties,
      employers: selectedAdvertisers,
      salaryMinimum: 0,
      salaryMaximum: MAX_SALARY,
      radius: 0,
      latitudeAnchor: 0,
      longitudeAnchor: 0,
      distanceUnit: "mi",
      employmentTypes: [],
      ageUnixTimestamp: 0,
      limit: pageSize,
      offset: (activePage - 1) * pageSize,
    });
    const endTime = new Date().getTime();
    setTimeTaken(endTime - startTime);

    setJobAdminDtos(response.jobAdminDtos);
    setTotalJobsCount(response.count);
    setTotalPages(Math.ceil(response.count / pageSize));
    setLoading(false);
  }

  return (
    <Flex px={12} direction={"column"}>
      <Text fw={500} mb={8}>
        Jobs Report
      </Text>
      <form>
        <Flex style={{ flexWrap: "wrap" }}>
          <TextInput
            placeholder={"search by keyword or job"}
            m={4}
            onChange={(event) => {
              store.dispatch(
                setCurrentSearchKeyword(event.currentTarget.value),
              );
            }}
          />

          {/* <Flex w={280} m={4}> */}
          <Select
            data={countries.map((country: Country) => country.countryName)}
            placeholder={"Select country"}
            size="sm"
            onChange={(value: any) => {
              store.dispatch(setSelectedCountries([value]));
            }}
            searchable
            m={4}
          />
          {/* </Flex> */}
          {/* <Flex w={200} m={4}>
            <CustomMultiSelect
              items={["item1", "item2", "item3"]}
              placeholder={"select job type"}
              label={"Job type"}
              size="sm"
              onValueChange={(value: string[]) => {
                store.dispatch(setSelectedJobType(value));
              }}
            />
          </Flex> */}

          <Flex w={200} m={4}>
            <CustomMultiSelect
              items={industries}
              placeholder={"select industries type"}
              label={"Industry"}
              size="sm"
              onValueChange={(value: string[]) => {
                store.dispatch(setSelectedIndustries(value));
              }}
            />
          </Flex>
          <Flex w={280} m={4}>
            <CustomMultiSelect
              items={["item1", "item2", "item3"]}
              placeholder={"select advertisers"}
              label={"Advertisers"}
              size="sm"
              onValueChange={(value: string[]) => {
                store.dispatch(setSelectedAdvertisers(value));
              }}
            />
          </Flex>
          <Button
            color="black"
            m={4}
            onClick={getJobsForAdmin}
            disabled={loading}
          >
            {loading && <Loader size="xs" mr={4} />}
            {loading && "Processing"}
            {!loading && "Search"}
          </Button>
        </Flex>
        <Space h={12} />
      </form>
      <Flex my={16} justify={"space-between"}>
        <Text fw={500} mr={4} size="sm">
          Job Count {totalJobCount}
          {timeTaken > 0 && ` - Time taken: ${timeTaken}ms`}
        </Text>

        <Flex>
          <Pagination
            size={"sm"}
            total={totalPages}
            value={activePage}
            onChange={(value) => setActivePage(value)}
          />
        </Flex>
      </Flex>

      <Table striped stickyHeader stickyHeaderOffset={10} highlightOnHover>
        <Table.Thead bg={"black"} c={"white"}>
          <Table.Tr>
            <Table.Th>Sender Reference</Table.Th>
            <Table.Th>Job Title</Table.Th>
            <Table.Th>Industry</Table.Th>
            <Table.Th>Job Type</Table.Th>
            <Table.Th>Employment Type</Table.Th>
            <Table.Th>Country</Table.Th>
            <Table.Th>Region</Table.Th>
            <Table.Th>City</Table.Th>
          </Table.Tr>
        </Table.Thead>
        {loading && (
          <Table.Tbody>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
              <Table.Tr key={index}>
                <Table.Td colSpan={8}>
                  <Skeleton height={20} my={2} width={"100%"} />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        )}

        {!loading && rows.length === 0 && (
          <Table.Tbody>
            <Table.Tr>
              <Table.Td colSpan={8} h={200}>
                <Text ta={"center"} fw={500}>
                  No jobs found for the selected criteria.
                </Text>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        )}

        {rows.length > 0 && <Table.Tbody>{rows}</Table.Tbody>}

        <Table.Caption>
          <Flex justify={"center"}>
            <Pagination
              size={"sm"}
              total={totalPages}
              value={activePage}
              onChange={(value) => setActivePage(value)}
            />
          </Flex>
        </Table.Caption>
      </Table>

      <JobEditor
        opened={jobEditorOpened}
        close={() => setJobEditorOpened(false)}
        job={selectedJob}
      />
    </Flex>
  );
}
